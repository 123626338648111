import * as React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { Meta } from "../../components/Meta";
import { theme } from "../../components/theme";
import { Helmet } from "react-helmet";

interface PriceProps {}

const Price: React.FC<PriceProps> = ({}) => {
  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>{`mogFlowts - Price`}</title>
      </Helmet>

      <Container>製作中・・・</Container>
    </Layout>
  );
};

export default Price;

const Container = styled.div`
  margin: 0 auto;

  width: 100vw;

  text-align: center;

  & > :nth-child(1) {
    margin: 0 auto;
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 40px 15px 80px;
    width: ${theme.width.main};
  }
`;
